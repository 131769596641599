var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-form-datepicker',{attrs:{"id":_vm.id,"placeholder":"Abrir calendario","hide-header":"","date-format-options":{
    year: 'numeric',
    month: 'short',
    day: '2-digit',
    weekday: 'short'
  },"start-weekday":"1","locale":"es-CL","label-help":"","date-disabled-fn":_vm.dateDisabledCalendar,"state":_vm.state === true
    ? false
    : null,"min":_vm.min,"max":_vm.max},on:{"input":_vm.input},model:{value:(_vm.fechaCalendario),callback:function ($$v) {_vm.fechaCalendario=$$v},expression:"fechaCalendario"}})}
var staticRenderFns = []

export { render, staticRenderFns }