<template>
  <b-form-datepicker
    v-model="fechaCalendario"
    :id="id"
    placeholder="Abrir calendario"
    hide-header
    :date-format-options="{
      year: 'numeric',
      month: 'short',
      day: '2-digit',
      weekday: 'short'
    }"
    start-weekday="1"
    locale="es-CL"
    label-help=""
    :date-disabled-fn="dateDisabledCalendar"
    :state="state === true
      ? false
      : null"
    :min="min"
    :max="max"
    @input="input"
  />
</template>

<script>
import {
  BFormDatepicker,
} from 'bootstrap-vue'

import { mapGetters, mapActions } from 'vuex'

// Formatos
import { formatos } from '@core/mixins/ui/formatos'

export default {
  components: {
    BFormDatepicker,
  },
  mixins: [formatos],
  data() {
    return {
      fechaCalendario: this.formatoFechaHoyCalendar(),
      min: null,
      max: null,
    }
  },
  computed: {
    ...mapGetters({
      getUser: 'auth/user',
      getPeriodosEstablecimiento: 'periodos/getPeriodosEstablecimiento',
    }),
  },
  props: {
    id: {
      type: String,
      required: true,
    },
    state: {
      type: Boolean,
      required: true,
    },
    tipo: {
      type: String,
      required: true,
    },
    fecha: {
      type: String,
      required: true,
    }
  },
  mounted() {
    this.cargaPeriodosEstablecimiento(this.getUser.id_establecimiento)
    this.input()
  },
  methods: {
    ...mapActions({
      fetchPeriodosEstablecimiento: 'periodos/fetchPeriodosEstablecimiento',
    }),
    cargaPeriodosEstablecimiento(idEstablecimiento) {
      this.fechaCalendario = this.fecha
      if (this.fechaCalendario === '') {
        this.fechaCalendario = this.formatoFechaHoyCalendar()
      }
      if (!this.getPeriodosEstablecimiento || this.getPeriodosEstablecimiento.length === 0) {
        this.fetchPeriodosEstablecimiento(idEstablecimiento).then(() => {
          this.calendar()
        })
      } else {
        this.calendar()
      }
    },
    calendar() {
      if (this.tipo === 'hastaFechaActual') {
        this.setHastaFechaActual()
      }
      if (this.tipo === 'hastaFechaFinal') {
        this.setHastaFechaFinal()
      }
    },
    setHastaFechaActual() {
      this.min = this.getPeriodosEstablecimiento[0].fecha_inicio
      if (this.formatoFechaHoyCalendar() > this.getPeriodosEstablecimiento[this.getPeriodosEstablecimiento.length - 1].fecha_termino) {
        this.max = this.getPeriodosEstablecimiento[this.getPeriodosEstablecimiento.length - 1].fecha_termino
      } else {
        this.max = this.formatoFechaHoyCalendar()
      }
    },
    setHastaFechaFinal() {
      this.min = this.getPeriodosEstablecimiento[0].fecha_inicio
      this.max = this.getPeriodosEstablecimiento[this.getPeriodosEstablecimiento.length - 1].fecha_termino
    },
    input() {
      this.$emit('changeCalendario', this.fechaCalendario)
    },
    dateDisabledCalendar(ymd, date) {
      // Disable weekends (Sunday = `0`, Saturday = `6`) and
      // disable days that fall on the 13th of the month
      const weekday = date.getDay()
      const day = date.getDate()
      // Return `true` if the date should be disabled
      // return weekday === 0 || weekday === 6 || day === 1
      return weekday === 0 || weekday === 6
    },
  }
}
</script>
