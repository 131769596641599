<template>
  <b-input-group
    :class="state === true
      ? 'border-danger rounded'
      : ''"
  >
    <cleave
      :id="id"
      v-model='horaForm'
      :options="time"
      class="form-control"
      :raw="false"
      placeholder="HH:MM"
    />
    <b-input-group-append v-if="showBtn">
      <b-form-timepicker
        v-model='horaForm'
        button-only
        hide-header
        no-close-button
        size='sm'
        aria-controls='hora'
        locale='es-CL'
        @keyup='formatHoraInput(horaForm)'
      />
    </b-input-group-append>
  </b-input-group>
</template>

<script>
import {
  BInputGroup, BInputGroupAppend, BFormTimepicker,
} from 'bootstrap-vue'

// CLEAVE
import Cleave from 'vue-cleave-component'
// eslint-disable-next-line import/no-extraneous-dependencies
import 'cleave.js/dist/addons/cleave-phone.us'

// Formatos
import { formatos } from '@core/mixins/ui/formatos'

export default {
  components: {
    BInputGroup,
    BInputGroupAppend,
    BFormTimepicker,
    Cleave,
  },
  mixins: [formatos],
  data() {
    return {
      horaForm: '',
      // datatimer
      time: {
        time: true,
        timePattern: ['h', 'm'],
      },
    }
  },
  props: {
    id: {
      type: String,
      required: true,
    },
    hora: {
      type: String,
      required: true,
    },
    state: {
      type: Boolean,
      required: true,
    },
    showBtn: {
      type: Boolean,
      required: true,
    },
  },
  watch: {
    horaForm(horaForm) {
      this.input(horaForm)
    }
  },
  mounted() {
    this.cargaHora(this.hora)
  },
  methods: {
    cargaHora(hora) {
      this.horaForm = hora
    },
    input(horaForm) {
      this.$emit('changeHora', this.horaForm)
    },
  }
}
</script>