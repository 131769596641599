<template>
  <salida-form
    btnSubmit="Crear Salida"
    :salida.sync="data"
    @processForm="agregar"
  />
</template>

<script>
import { mapActions } from 'vuex'
import store from '@/store/index'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import Ripple from 'vue-ripple-directive'

import SalidaForm from './SalidaForm.vue'

export default {
  components: {
    // COMPONENTES
    SalidaForm,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      data: {
        fecha: '',
        motivo: '',
        hora_salida: '10:00',
        hora_regreso: '12:00',
        id_persona_rol_estudiante: null,
        id_persona_rol_retira: null,
      },
    }
  },
  methods: {
    ...mapActions({
      createSalida: 'salidas/addSalida',
    }),
    agregar(salida) {
      this.createSalida(salida).then(() => {
        const statusSalidas = store.state.salidas.status
        if (statusSalidas === 'success') {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Salida guardada 👍',
              icon: 'CheckIcon',
              text: 'La salida fue ingresada con éxito!',
              variant: 'success',
            },
          },
          {
            position: 'bottom-right',
            timeout: 4000,
          })

          this.$router.replace({
            name: 'salidas',
          })
        } else {
          this.$swal({
            title: 'Atención!',
            text: store.state.salidas.message,
            icon: 'warning',
            customClass: {
              confirmButton: 'btn btn-primary',
            },
            buttonsStyling: false,
          })
        }
      })
    },
  },
}
</script>
